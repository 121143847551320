/* OrderPage.css */
.popup {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup.open {
  display: block;
}

.popup-inner {
  position: relative;
  margin: auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  top: 50%;
  transform: translateY(-50%);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.items-list {
  max-height: 300px;
  overflow-y: auto;
}
