.navbar-fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
  }
  
  .navbar-hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .navbar-toggler {
    border: none;
  }
  
  .navbar-toggler:focus {
    outline: none;
  }
  
  .collapse.navbar-collapse.show {
    display: block;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #000;
  }
  
  .badge {
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  .position-relative {
    position: relative;
  }
  
  .position-absolute {
    position: absolute;
  }
  
  .top-0 {
    top: 0;
  }
  
  .start-100 {
    left: 100%;
  }
  
  .translate-middle {
    transform: translate(-50%, -50%);
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.375rem;
  }
  
  .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  
  .dropdown-menu-start {
    right: auto;
    left: 0;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .show {
    display: block;
  }
  
  @media (max-width: 767px) {
    .navbar-brand {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  
    .navbar-toggler {
      border: none;
    }
  
    .navbar-toggler:focus {
      outline: none;
    }
  
    .collapse.navbar-collapse.show {
      display: block;
    }
  
    .nav-link {
      display: block;
      padding: 0.5rem 1rem;
      color: #000;
    }
  
    .navbar-collapse {
      text-align: center;
    }
  
    .form-control {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  