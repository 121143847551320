.notification-badge {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: white;
    background-color: red;
    border-radius: 50%;
  }