
  .avatar-edit-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #007bff;
    color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
  