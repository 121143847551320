/* AdsNavBar.css */
.ads-navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: black;
  overflow: hidden;
  z-index: 1000;
}

.ads-navbar {
  display: flex;
  white-space: nowrap;
  animation: scroll 10s linear infinite;
  align-items: center; /* Center the text vertically */
  height: 100%; /* Ensure the navbar takes the full height of the container */
}

.ads-navbar:hover {
  animation-play-state: paused;
}

.ad-title {
  color: white;
  padding: 0 1rem; /* Adjust padding to fit within the 20px height */
  line-height: 20px; /* Center the text vertically */
  cursor: pointer;
  display: flex;
  align-items: center; /* Ensure the text is centered vertically */
  height: 100%; /* Ensure the title takes the full height of the navbar */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
