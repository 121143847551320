@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom RTL Styles */
[dir="rtl"] .text-right {
  text-align: left;
}
[dir="rtl"] .text-left {
  text-align: right;
}
[dir="rtl"] .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

