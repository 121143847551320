.fixed-inset-0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8); /* Tailwind's bg-black bg-opacity-75 equivalent */
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.fixed-inset-0.visible {
  opacity: 1;
  visibility: visible;
}

.relative-popup {
  position: relative;
  width: 90%;
  max-width: 600px;
  border: 4px solid white; /* Add border */
  border-radius: 0.5rem; /* Tailwind's rounded-lg equivalent */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); /* Tailwind's shadow-lg equivalent */
  background: #fff;
  transform: scale(0.8);
  transition: transform 0.3s;
}

.relative-popup.visible {
  transform: scale(1);
}

.popup-image {
  width: 100%;
  height: auto;
  border-radius: 0.5rem 0.5rem 0 0; /* Tailwind's rounded-t-lg equivalent */
}

.popup-image.hidden {
  display: none;
}

.popup-image.visible {
  display: block;
}

.loading-spinner,
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem; /* Adjust as necessary */
  color: white;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5rem; /* Tailwind's p-6 equivalent */
  background: rgba(0, 0, 0, 0.5); /* Tailwind's bg-black bg-opacity-50 equivalent */
  color: white;
}

.close-button {
  position: absolute;
  top: 0.5rem; /* Tailwind's top-2 equivalent */
  right: 0.5rem; /* Tailwind's right-2 equivalent */
  font-size: 1.25rem; /* Tailwind's text-xl equivalent */
  font-weight: bold; /* Tailwind's font-bold equivalent */
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Background color for better visibility */
  border: none;
  border-radius: 0.25rem; /* Tailwind's rounded equivalent */
  cursor: pointer;
  padding: 0.25rem; /* Tailwind's p-1 equivalent */
}
