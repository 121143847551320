.productDetails-container {
  max-width: 1200px;
  margin: 0 auto;
}

.position-relative {
  position: relative;
}

.img-thumbnail {
  width: 4rem; /* Fixed width for thumbnails */
  height: 4rem; /* Fixed height for thumbnails */
  object-fit: cover;
  cursor: pointer;
  border-radius: 0.25rem;
}

.img-thumbnail:hover {
  border-color: #adb5bd;
}

.product-thumbnails {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  padding-bottom: 0.5rem; /* Optional: space for scrollbar */
}


.product-thumbnails::-webkit-scrollbar-thumb {
  background: #adb5bd; /* Customize scrollbar thumb color */
  border-radius: 4px;
}

.product-thumbnails::-webkit-scrollbar-track {
  background: #f1f1f1; /* Customize scrollbar track color */
}

.product-thumbnails::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for WebKit browsers */
}
.thumbnail-wrapper {
  flex: 0 0 auto; /* Prevent shrinking */
}




a {
  text-decoration: none;
}

.text-danger {
  color: #dc3545 !important;
}

.text-secondary {
  color: #6c757d !important;
}




/* rating */
.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comment-content {
  background: #f0f2f5;
  padding: 10px;
  border-radius: 10px;
  flex: 1;
}

.comment-user {
  font-weight: bold;
  margin: 0;
}

.comment-text {
  margin: 5px 0;
}

.comment-date {
  font-size: 0.8em;
  color: #555;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 16px; /* Space between buttons */
  margin-bottom: 16px; /* Space below the buttons */
}

.rating-icon {
  font-size: 40px; /* Customize the size */
  color: gold; /* Customize the color */
}

.rating-button {
  display: flex;
  align-items: center;
}


.rating-count {
  color: rgb(12, 11, 7);
  margin: 8px 0 50px 0;
  font-size: 24px;
  font-weight: bolder;
  text-decoration: none; 
}


.image-magnify-container {
  width: 100%;
  height: 500px; /* Set your desired fixed height */
  position: relative;
  overflow: hidden;
}

.react-image-magnify-small-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

.img-thumbnail {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  cursor: pointer;
  border-radius: 0.25rem;
}

.img-thumbnail:hover {
  border-color: #adb5bd;
}

.product-thumbnails {
  display: flex;
  overflow-x: scroll; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  padding-bottom: 0.5rem; /* Optional: space for scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.product-thumbnails::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for WebKit browsers */
}

.thumbnail-wrapper {
  flex: 0 0 auto; /* Prevent shrinking */
}
