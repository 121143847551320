.footer-container {
    background-color: rgba(144, 158, 169, 1);
  }
  
  .footer-logo {
    height: 40px;
    margin-right: 10px;
  }
  
  .footer-text {
    color: white;
  }
  
  .footer-social-icons a {
    margin: 0 10px;
    color: white;
    transition: color 0.3s;
  }
  
  .footer-social-icons a:hover {
    color: blue;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 5px;
  }
  
  .footer-links a {
    color: white;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: blue;
  }
  
  .footer-newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-newsletter input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .footer-newsletter button {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .footer-newsletter button:hover {
    background-color: darkblue;
  }
  
  .footer-logos img {
    margin: 0 10px;
  }
  
  @media (min-width: 768px) {
    .footer-newsletter {
      flex-direction: row;
    }
  
    .footer-newsletter input {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
  