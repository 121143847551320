.home-page {
  width: 100%;
}

.home-container {
  width: 100%;
}

.home-section {
  margin-bottom: 2rem;
  text-align: center;
}

.home-section-title {
  margin-bottom: 1rem;
}

.home-section-content {
  position: relative;
  display: flex;
  align-items: center;
}

.home-cards-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.home-cards-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.ecommerce-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 100%;
  overflow: hidden; /* Hide overflow content */
  box-shadow: none; /* Remove box shadow */
}

.ecommerce-card .card-content {
  flex: 1 0 auto;
  overflow-y: auto; /* Allow scrolling for overflowing content */
}

.ecommerce-card .card-footer {
  flex-shrink: 0;
}

/* Remove underline from links */
a {
  text-decoration: none;
}

@media (min-width: 576px) {
  .ecommerce-card {
    max-height: 400px; /* Increase height slightly for tablets */
  }
}

@media (min-width: 768px) {
  .ecommerce-card {
    flex: 1 0 32%;
    max-height: 450px; /* Increase height for medium devices */
  }
}

@media (min-width: 992px) {
  .ecommerce-card {
    flex: 1 0 23%;
    max-height: 500px; /* Increase height for large devices */
  }
}

@media (min-width: 1200px) {
  .ecommerce-card {
    flex: 1 0 18%;
    max-height: 550px; /* Increase height for extra large devices */
  }
}
