.scroll-container {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 0.5rem;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.zoom-container {
  overflow: hidden;
}

.zoom-image {
  transition: transform 0.5s ease;
}

.zoom-container:hover .zoom-image {
  transform: scale(1.1);
}


@media (max-width: 768px) {
  .description {
    display: block;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
