.slideshow-container {
  width: 100%;
  height: 70vh; /* Adjust the height as needed */
  position: relative;
  overflow: hidden;
}

.slide {
  position: relative;
  cursor: pointer;
}

.slide-image {
  width: 100%;
  height: 70vh; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
}

.description-overlay {
  position: relative;
  position: absolute;
  top: 0px;
  left: 5%;
  transform: translateX(-50%);
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
}

.button-content {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .description-overlay {
    width: 70%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .description-overlay {
    width: 60%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .description-overlay {
    width: 50%; /* or any desired width for extra large screens */
  }
}

.text-content {
  display: block;
}

.button-content {
  display: none;
}

@media (max-width: 768px) {
  .text-content {
    display: none;
  }
  .button-content {
    display: block;
  }
}

.second-slide_container {
  width: 100%;
  height: 60vh; 
  position: relative;
  overflow: hidden;
}

.second-slide_slide {
  position: relative;
  cursor: pointer;
}

.second-slide_image {
  width: 100%;
  height: 60vh; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-slide_description-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.second-slide_description-overlay a {
  color: #fff; /* Link color */
  margin-top: 10px;
  text-decoration: underline;
}

.second-slide_link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.second-slide_link:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.second-slide_slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}
.second-slide_container {
  width: 100%;
  height: 60vh; 
  position: relative;
  overflow: hidden;
}

.second-slide_slide {
  position: relative;
  cursor: pointer;
}

.second-slide_image {
  width: 100%;
  height: 60vh; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-slide_description-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .second-slide_description-overlay {
    width: 70%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .second-slide_description-overlay {
    width: 60%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .second-slide_description-overlay {
    width: 50%; /* or any desired width for extra large screens */
  }
}

.second-slide_description-overlay a {
  color: #fff; /* Link color */
  margin-top: 10px;
  text-decoration: underline;
}

.second-slide_link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.second-slide_link:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.button-content {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .second-slide_description-overlay {
    display: none;
  }
  .button-content {
    display: block;
  }
}

.second-slide_slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}
