.testimonials-container {
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
}

.testimonials-slider {
  position: relative;
  overflow: hidden;
}

.testimonials-list {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 1rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.testimonials-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.testimonial-card {
  flex: 0 0 100%;
  background-color: transparent; /* Remove card background */
}

.testimonial-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto; /* Center the image */
}

.testimonial-review {
  font-size: 0.9rem;
  color: #555;
  text-align: center;
}

.testimonial-rating span {
  color: #ffb400;
}

.testimonial-date {
  font-size: 0.8rem;
  color: #888;
  margin-top: 0.5rem;
  text-align: center;
}

.testimonial-product {
  margin-top: 10px;
}

.testimonial-product h4 {
  margin: 0;
  text-align: center;
}

.product-link {
  display: inline-block;
  margin-top: 5px;
  padding: 5px 10px;
  background-color: rgba(144, 158, 169, 1);
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.product-link:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .testimonials-list {
    justify-content: center;
  }

  .testimonial-card {
    flex: 0 0 45%;
  }
}

@media (min-width: 1024px) {
  .testimonial-card {
    flex: 0 0 30%;
  }
}
